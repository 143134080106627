export const formSpec = {
  schema: {
    title: '',
    type: 'object',
    "required": ["case_number", "patenttype", "contents"],
      "properties": {
        "case_number": {
          "title": "專利申請案號",
          "type": "string"
        },
        "name": {
          "title": "姓名",
          "type": "string",
          "readOnly": true
        },
        "email": {
          "title": "信箱",
          "type": "string"
        },
        "patenttype": {
          "type": "string",
          "title": "專利類型",
          "enum": ["發明專利", "設計專利"]
        },
        "pendingstage": {
          "type": "string",
          "title": "審查階段",
          "enum": ["初審", "再審"]
        },
        "contents": {
          "title": "回饋內容",
          "type": "object",
          "properties": {
            "qualitygood": {
              "type": "boolean",
              "title": "審查品質優異"
            },
            "qualitybad": {
              "type": "boolean",
              "title": "審查品質缺失"
            },
            "r1": {
              "type": "boolean",
              "title": "R1 審查版本錯誤"
            },
            "r2": {
              "type": "boolean",
              "title": "R2 引證不適格"
            },
            "r3": {
              "type": "boolean",
              "title": "R3 請求項漏未審查/檢索/未逐項論述"
            },
            "r4": {
              "type": "boolean",
              "title": "R4 理由論述錯誤"
            },
            "r5": {
              "type": "boolean",
              "title": "R5 審定書/OA內容整段誤繕"
            },
            "y1": {
              "type": "boolean",
              "title": "Y1 檢索式明顯錯誤"
            },
            "y2": {
              "type": "boolean",
              "title": "Y2 理由論述不充分/不恰當"
            },
            "y3": {
              "type": "boolean",
              "title": "Y3 法條引用不當"
            },
            "b1": {
              "type": "boolean",
              "title": "B1 基本資料有誤"
            },
            "b2": {
              "type": "boolean",
              "title": "B2 審定書/OA部分內容誤繕或漏記"
            },
            "b3": {
              "type": "boolean",
              "title": "B3 審查表/檢索報告內容誤繕或漏記"
            },
            "b4": {
              "type": "boolean",
              "title": "B4 理由論述欠明確/詞不達意"
            }
          }
        },
        "description": {
          "title": "詳情說明(非必填)",
          "type": "string"
        },
        "status": {
          "type": "string",
          "title": "個案現況",
          "enum": [
            "已與智慧局電話溝通(審查官→科長→組長)",
            "已透過局長信箱傳達。"
          ]
        }
      }
    },
    "uiSchema": {
      "description": {
        "ui:widget": "textarea",
        "ui:help": "1.本表單內容彙整後，將提供給智慧局進行審查品質稽核參考，其回饋內容有公開之可能，請專利師填寫時，留意營業秘密保護。 \n2.本表單無立即提供給智慧局，約半年至一年方會彙整提供至智慧局。"
      },
      "contents": {
        "ui:widget": "checkboxes"
      }
    }
};
